<div class="header-container">
  <mat-spinner  class="snack-spinner" *ngIf="isLoading==true" diameter="40"></mat-spinner>
  <template #customHeader>

  </template>
</div>
<div class="form-area">
  <div [@openClose]="isOpen ? true : false" class="side-bar">
    <mat-spinner  class="snack-spinner" *ngIf="isLoading==true"  diameter="30"></mat-spinner>
    <ul class="form-list">
      <li (click)="isOpen = !isOpen" *ngIf="isLoading==false">
        <mat-icon class="form-list-icon">swap_horiz</mat-icon>
        <span>Collapse </span>
      </li>
      <li
        *ngFor="let item of forms"
        (click)="selectSideMenu(item?.formName,item?.formTypeId,item)"
        [ngClass]="
          formService.selectedForm == item?.formName ? 'li-active' : 'li-non-active'
        "
        >
        <mat-icon class="form-list-icon">{{ item?.icon }}</mat-icon>
        <span>{{ item?.displayName }} </span>
      </li>
    </ul>
  </div>
  <ng-container>
    <form-loader *ngIf="isLoading==true" style="width:100%;">

    </form-loader>
  </ng-container>
  <ng-container *ngIf="isLoading==false">
  <ng-container  *ngFor="let form of forms">
    <div class="form-body" *ngIf="form?.name == formService.selectedForm" >
      <form class="example-form" (ngSubmit)="onSubmit(form.groupName)" *ngIf="formService.isLoading" [formGroup]="formService.formGroups[form.groupName]">
        <h2 class="form-title">{{ form?.name }}</h2>
        <div *ngIf="form?.formMessage" class="form-message">
          <p class="form-message-title"><mat-icon>info</mat-icon>Form Information</p>
          <p class="form-message-message">{{form?.formMessage}}</p>
        </div>
        <div class="section" *ngFor="let section of form?.sections">
          <div class="section-body">
            <div class="section-header">
              <div class="number-circle">
                <span class="number"> {{ section?.order }}.</span>
              </div>
              <div class="section-title-area">
                <p class="section-title">{{ section?.name }}</p>
              </div>
            </div>
            <div class="section-body">
              <fieldset [formGroupName]="section.groupName" style="width:100%">
              <mat-grid-list
                [cols]="columnSize"
                rowHeight="78px"
                gutterSize="10px"
              >
                <mat-grid-tile
                  *ngFor="let c of section?.controls"
                  [colspan]="columnSize > 1 ? (c?.colSpan != null? c?.colSpan : columnSize) : 1"
                  [rowspan]="c?.rowSpan != null? c?.rowSpan:groupRowSize"
                  [ngClass]="c?.type=='multiSelectGroupControl'?'multi-group' :''"
                >
                 <social-security-control-component
                    *ngIf="c?.type == 'socialSecurity'"
                    [formObject]="c"
                    [formName]="form.formName"
                    [formGroup]="formService.formGroups[form.groupName]"
                    [sectionGroupName]="section.groupName"
                  ></social-security-control-component>
                  <phone-number-control-component
                    [label]="c?.label"
                    [placeholder]="c?.placeholder"
                    [formObject]="c"
                    [formName]="form.formName"
                    [formGroup]="formService.formGroups[form.groupName]"
                    [sectionGroupName]="section.groupName"
                    *ngIf="c?.type == 'phoneNumber'"
                  ></phone-number-control-component>
                  <!--<multi-select-group-control
                    [formObject]="c"
                    [formName]="form.formName"
                    [formGroup]="formService.formGroups[form.groupName]"
                    [sectionGroupName]="section.groupName"
                    *ngIf="c?.type == 'multiSelectGroupControl'"
                    >
                    </multi-select-group-control>-->
                  <mat-form-field
                    *ngIf="c?.type == 'dropdown'"
                    appearance="fill"
                  >
                    <mat-label>{{ c?.label }}</mat-label>
                    <mat-select [formControlName]="c.name" [required]="formService.formGroups[form.groupName].controls[section.groupName].controls[c.name]?.errors?.required">
                      <mat-option
                        *ngFor="let val of c?.values"
                        [value]="val[c?.valueText]"
                      >
                        {{ val[c?.valueShown] }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="c?.type == 'text'" appearance="fill" >
                    <mat-label>{{ c?.label }}</mat-label>
                    <input matInput [formControlName]="c.name"  [placeholder]="c?.placeholder"  [required]="formService.formGroups[form.groupName].controls[section.groupName].controls[c.name]?.errors?.required"/>
                  </mat-form-field>
                  <div *ngIf="c?.type == 'date'" [ngClass]="c?.type == 'date' && c?.placeholder.length > 0 ? 'special-date-div' : 'regular-date-div'">
                    <span class="date-special-placeholder" *ngIf="c?.label== 'Date of Hearing'"><mat-icon class="date-special-placeholder">info</mat-icon>{{c?.placeholder}}</span>
                    <mat-form-field *ngIf="c?.type == 'date'" appearance="fill">
                      <mat-label>{{ c?.label }}</mat-label>
                      <input matInput [formControlName]="c.name" [matDatepicker]="picker1"  [required]="formService.formGroups[form.groupName].controls[section.groupName].controls[c.name]?.errors?.required"/>
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker1"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <mat-form-field
                    *ngIf="c?.type == 'textarea'"
                    appearance="fill"
                  >
                    <mat-label>{{ c?.label }}</mat-label>
                    <textarea
                      matInput
                      [placeholder]="c?.placeholder" [formControlName]="c.name"
                      rows="7"
                      [required]="formService.formGroups[form.groupName].controls[section.groupName].controls[c.name]?.errors?.required"
                    ></textarea>
                  </mat-form-field>
                  <mat-form-field
                    class="custom-file-select"
                    *ngIf="c?.type =='file'"
                    appearance="fill">
                    <mat-label *ngIf="formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].value?.name; else newFile">{{ formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].value.name}}</mat-label>
                    <ng-template #newFile>
                        <mat-label>Choose file</mat-label>
                    </ng-template>
                    <input  matInput disabled>
                    <button type="button" mat-icon-button matSuffix (click)="fileInput.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <input [formControlName]="c.name" hidden (change)="selectFile($event,form,section, c)" #fileInput type="file" id="file">
                </mat-form-field>
                  <div *ngIf="(formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].invalid && (formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].dirty || formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].touched)) || formService.submittedForms[form.groupName]" [ngClass]="c?.type == 'date' && c?.placeholder.length > 0 ? 'validation-hint-special' : 'validation-hint'">
                    <div *ngIf="formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].errors?.required">
                    {{c.label}} is required.
                    </div>
                    <div *ngIf="formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].errors?.minlength">
                      {{c.label}} must be of length {{formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].errors.minlength.requiredLength}}.
                    </div>
                    <div *ngIf="formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].errors?.email">
                      {{c.label}} must be in format ***@***.***
                    </div>
                  </div>
                  <div *ngIf="c?.type =='multiGroup'" style="width:100%; height:100%; margin-top: 10px;">
                    <app-multi-group-control [formGroup]="formService.formGroups[form.groupName]" [groupControl]="c" [groupName]="form.groupName" [sectionName]="section.groupName" [secondaryCompanyColor]="secondaryCompanyColor" [secondaryLabelColor]="secondaryLabelColor"></app-multi-group-control>
                   <!-- <p class="multi-group-label">Medical Providers</p>
                    <div class="multi-area" [ngClass]="groupRowSize > 4 ? 'group-small-area':''">
                      <div class="left-area">
                      <fieldset formGroupName="medicalProviders" style="width:100%; height:100%">
                        <mat-grid-list cols="1"
                        rowHeight="65px"
                        gutterSize="10px">
                        <mat-grid-tile
                          *ngFor="let ctrl of c.controls"
                          [colspan]="columnSize > 1 ? ctrl?.colSpan : 1"
                          [rowspan]="ctrl?.rowSpan"
                        >
                        <mat-form-field *ngIf="ctrl?.type == 'text'" appearance="fill" >
                          <mat-label>{{ ctrl?.label }}</mat-label>
                          <input matInput [formControlName]="ctrl.name"  [placeholder]="ctrl?.placeholder"  [required]="formService.formGroups[form.groupName].controls[section.groupName].controls['medicalProviders'].controls[c.name]?.errors?.required"/>
                        </mat-form-field>
                        </mat-grid-tile>
                          <mat-grid-tile colspan="1" rowSpan="1">
                            <button type="button" mat-raised-button color="primary" class="add-group-btn" (click)="testGroupAdd()" >Add Medical Provider</button>
                          </mat-grid-tile>
                        </mat-grid-list>
                      </fieldset>
                      </div>
                      <div class="right-area">
                        <div class="no-inputs-area" *ngIf="dataSource?.data?.length == 0">
                          <span>No Medical Providers</span>
                        </div>
                        <div class="mat-elevation-z8" *ngIf="dataSource?.data?.length > 0">
                          <table mat-table [dataSource]="dataSource" style="width:100%">
                            <ng-container matColumnDef="delete">
                              <th mat-header-cell *matHeaderCellDef> Delete </th>
                              <td mat-cell *matCellDef="let element">
                                <span (click)="deleteRow(element.delete)" class="material-icons">
                                  delete
                                </span>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef> Name </th>
                              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="weight">
                              <th mat-header-cell *matHeaderCellDef> Weight </th>
                              <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                            </ng-container>

                            <ng-container matColumnDef="symbol">
                              <th mat-header-cell *matHeaderCellDef> Symbol </th>
                              <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>

                          <mat-paginator #multiPaginator [pageSize]="4" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
                        </div>
                      </div>
                    </div>-->
                  </div>
                  <app-contract-control *ngIf="c?.type =='signableForm'"
                  [formGroup]="formService.formGroups[form.groupName]"
                  [signableFormControl]="c"
                  [groupName]="form.groupName"
                  [sectionName]="section.groupName"
                  [secondaryCompanyColor]="secondaryCompanyColor"
                  [secondaryLabelColor]="secondaryLabelColor"
                  [documentTitle]="c.documentTitle"
                  [documentPath]="c.documentPath"
                  class="contract-control">
                  </app-contract-control>

                 </mat-grid-tile>
                 <!-- <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <app-contract-control
                  [secondaryCompanyColor]="secondaryCompanyColor"
                  [secondaryLabelColor]="secondaryLabelColor"
                  documentTitle="Fee Contract"
                  documentPath="/assets/firstTest.pdf"
                  class="contract-control"></app-contract-control>
                 </mat-grid-tile> -->
               <!-- <mat-grid-tile class="multi-group" *ngIf="section?.name =='Personal Information'" [colspan]="columnSize" [rowspan]="groupRowSize">

                   <p class="multi-group-label">Medical Providers</p>
                    <div class="multi-area" [ngClass]="groupRowSize > 4 ? 'group-small-area':''">
                      <div class="left-area">
                        <fieldset formGroupName="testGroupControl" style="width:100%">
                        <mat-grid-list cols="1"
                        rowHeight="65px"
                        gutterSize="10px">
                        <mat-grid-tile
                          *ngFor="let c of testGroupControls"
                          [colspan]="columnSize > 1 ? c?.colSpan : 1"
                          [rowspan]="c?.rowSpan"
                        >
                        <mat-form-field *ngIf="c?.type == 'text'" appearance="fill" >
                          <mat-label>{{ c?.label }}</mat-label>
                          <input matInput [formControlName]="c.name"  [placeholder]="c?.placeholder"  [required]="formService.formGroups[form.groupName].controls[section.groupName].controls['testGroupControl'].controls[c.name]?.errors?.required"/>
                        </mat-form-field>
                        </mat-grid-tile>
                          <mat-grid-tile colspan="1" rowSpan="1">
                            <button type="button" mat-raised-button color="primary" class="add-group-btn" (click)="testGroupAdd()" >Add Medical Provider</button>
                          </mat-grid-tile>
                        </mat-grid-list>
                      </fieldset>
                      </div>
                      <div class="right-area">
                        <div class="no-inputs-area" *ngIf="dataSource?.data?.length == 0">
                          <span>No Medical Providers</span>
                        </div>
                        <div class="mat-elevation-z8" *ngIf="dataSource?.data?.length > 0">
                          <table mat-table [dataSource]="dataSource" style="width:100%">
                            <ng-container matColumnDef="delete">
                              <th mat-header-cell *matHeaderCellDef> Delete </th>
                              <td mat-cell *matCellDef="let element">
                                <span (click)="deleteRow(element.delete)" class="material-icons">
                                  delete
                                </span>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef> Name </th>
                              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="weight">
                              <th mat-header-cell *matHeaderCellDef> Weight </th>
                              <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                            </ng-container>

                            <ng-container matColumnDef="symbol">
                              <th mat-header-cell *matHeaderCellDef> Symbol </th>
                              <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>

                          <mat-paginator #multiPaginator [pageSize]="4" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
                        </div>
                      </div>
                    </div>
                </mat-grid-tile>-->
              </mat-grid-list>
            </fieldset>
            </div>
          </div>
        </div>
        <button [disabled]="isFormUploading" [hidden]="lawName == 'demo'" class="form-submit-btn"  style="float:right" mat-raised-button >Submit
          <mat-spinner class="loading-spinner" *ngIf="isFormUploading" diameter="30"></mat-spinner>
        </button>
      </form>
    </div>
  </ng-container>
</ng-container>
<button [hidden]="lawName != 'demo'" class="go-back-btn" mat-raised-button (click)="goBack()">Go Back</button>
</div>
<dc-footer2></dc-footer2>
